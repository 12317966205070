import React from 'react';
import {StaticImage} from "gatsby-plugin-image";
import {Container, Row, Col} from 'react-bootstrap'
import '../styles/thankyou.css';

const ThankYou = () => {
    return(
        <Container className="thanks-container" fluid>
            <Row className="thanks-nav"></Row>
            <Row className="justify-content-center align-items-center mx-5">
                <Col xs={12} md={6} className="mt-2">                    
                    <h2 className=""><span className="ty-header">Congrats!</span></h2>
                    <p className="text-bold">You’ve just made a great business decision. </p>                    
                    <p>
                        You are now one step closer to scaling your business and boosting your exposure. We appreciate you contacting us and someone from our team will get back to you before you have a chance to refresh this page.
                    </p>
                    <p>
                        Okay, maybe not that fast.
                    </p>
                    <p>
                        But we will be in touch very quickly.
                    </p>
                    <p>
                        We can’t wait to start working with you!
                    </p>
                </Col>
                <Col xs={12} md={6} className="mt-2">
                    <StaticImage
                        src="../images/teamwork.webp"
                        placeholder="blurred"
                        layout="constrained"
                        alt=""
                    />
                </Col>
            </Row>
            <Row className="justify-content-center mb-5" fluid>
                <Col className="col-6">
                    <p className="text-center text-bold">
                        Sharing is caring. Know someone who needs a website or our strategic digital marketing services? Tell them about us. We reward all referrals. 
                    </p>
                    <p className="text-center">
                        <a 
                            href="
                                mailto:?Subject=Check%20Out%20Codev%20Media&body=I%20just%20contacted%20Codev%20Media%20about%20web%20design%20and%20SEO,%20and%20I%20think%20you%20might%20be%20interested%20in%20their%20services%20too:%20https://www.codevmedia.com
                            "
                            rel="EMAIL"
                        >
                            Click here to send your contacts a quick email. 
                        </a>
                    </p>
                </Col>
            </Row>
        </Container>
    );
};

export default ThankYou;