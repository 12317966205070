import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import ThankYou from "../components/thankyou"

const Faq = () => (
  <Layout>
    <SEO title="Thank You" description="Congrats on taking the next step with Codev Media" />
    <ThankYou />
  </Layout>
)

export default Faq;